@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  /* -webkit-box-shadow: 0 0 0px 1000px #1b1b6c inset; */
  /* background-color: #1b1b6c; */
  transition: background-color 5000s ease-in-out 0s;
}

html.dark input {
  &:-webkit-autofill {
    /* box-shadow: 0 0 0 30px #1c1c1d inset; */
    -webkit-text-fill-color: white;
  }

  &:-webkit-autofill:hover {
    /* box-shadow: 0 0 0 30px #1c1c1d inset; */
    -webkit-text-fill-color: white;
  }

  &:-webkit-autofill:focus {
    /* box-shadow: 0 0 0 30px #1c1c1d inset; */
    -webkit-text-fill-color: white;
  }

  &:-webkit-autofill:active {
    /* box-shadow: 0 0 0 30px #1c1c1d inset; */
    -webkit-text-fill-color: white;
  }
}

#skillList,
#message {
  &::-webkit-scrollbar {
    height: 5px;
    position: fixed;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(33, 0, 48, 0.774);
    border-radius: 5%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(33, 0, 48);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Oswald:wght@200;300;400;500;600;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

@layer base {
  @font-face {
    font-family: Neue Montreal;
    font-style: normal;
    font-weight: 300;
    src: url(./fonts/NeueMontreal/NeueMontreal-Light.otf) format("otf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: italic;
    font-weight: 300;
    src: url(./fonts/NeueMontreal/NeueMontreal-LightItalic.otf) format("otf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: normal;
    font-weight: 400;
    src: url(./fonts/NeueMontreal/NeueMontreal-Regular.otf) format("otf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: italic;
    font-weight: 400;
    src: url(./fonts/NeueMontreal/NeueMontreal-Italic.otf) format("otf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: normal;
    font-weight: 500;
    src: url(./fonts/NeueMontreal/NeueMontreal-Medium.otf) format("ptf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: italic;
    font-weight: 500;
    src: url(./fonts/NeueMontreal/NeueMontreal-MediumItalic.otf) format("ptf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: normal;
    font-weight: 700;
    src: url(./fonts/NeueMontreal/NeueMontreal-Bold.otf) format("ptf");
  }
  @font-face {
    font-family: Neue Montreal;
    font-style: italic;
    font-weight: 700;
    src: url(./fonts/NeueMontreal/NeueMontreal-BoldItalic.otf) format("ptf");
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Neue Montreal", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
